import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import ClipLoader from 'react-spinners/ClipLoader';

const ProductImage = ({ url }) => {
  const context = useContext(LanguageContext);
  const { language } = context;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.onload = () => {
      setLoading(false);
      document.getElementById(`image-${url}`).src = url;
    };
    img.onerror = () => {
      setError(true);
      setLoading(false);
    };
    img.src = url;
    return () => (img.src = '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (loading) {
    return <ClipLoader size={35} color='#CAD7E7' />;
  }

  if (error) {
    return (
      <span>{language === 'en' ? 'Image not found' : 'Kuvaa ei löytynyt'}</span>
    );
  }

  return (
    <img id={`image-${url}`} alt='' className='object-contain w-full h-full' />
  );
};

export default ProductImage;

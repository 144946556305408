import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState('en');

  // Check if language preference in LocalStorage
  useEffect(() => {
    const languagePreference = localStorage.getItem('language');

    if (languagePreference === 'en') {
      setLanguage('en');
    } else if (languagePreference === 'fi') {
      setLanguage('fi');
    } else if (languagePreference === null) {
      localStorage.setItem('language', 'en');
    }
  }, []);

  // Change language and add/remove language preference in LocalStorage
  const changeLanguage = () => {
    if (language === 'en') {
      setLanguage('fi');
      localStorage.setItem('language', 'fi');
    } else if (language === 'fi') {
      setLanguage('en');
      localStorage.setItem('language', 'en');
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

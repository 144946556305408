import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import Partners from './Partners';

import EnContent from '../../content/en/info';
import FiContent from '../../content/fi/info';

const Info = () => {
  const context = useContext(LanguageContext);
  const { language } = context;

  // Select content JSON file based on language
  let content;
  language === 'en' ? (content = EnContent) : (content = FiContent);

  return (
    <div className='max-w-5xl flex flex-col items-center mx-auto sm:px-12 pt-16'>
      <div className='w-full max-w-2xl text-fmi-blue bg-white sm:rounded-b-md border-top-fmi shadow-fmi py-8 px-6 sm:px-12'>
        <h2 className='text-2xl font-semibold text-fmi-blue'>
          {language === 'en' ? 'About SAMPO' : 'Tietoa SAMPO:sta'}
        </h2>
        {content.map((object) => {
          return (
            <p key={object} className='pt-6'>
              {object.paragraph}
            </p>
          );
        })}
      </div>
      <Partners />
    </div>
  );
};

export default Info;

import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share';

const SocialShare = ({ url }) => {
  return (
    <div className='flex justify-center items-center my-2'>
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round className='mx-2' />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon size={32} round className='mx-2' />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={32} round className='mx-2' />
      </LinkedinShareButton>
      <RedditShareButton url={url}>
        <RedditIcon size={32} round className='mx-2' />
      </RedditShareButton>
      <EmailShareButton url={url}>
        <EmailIcon size={32} round className='mx-2' />
      </EmailShareButton>
    </div>
  );
};

export default SocialShare;

import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import EnContent from '../../content/en/faq';
import FiContent from '../../content/fi/faq';

const Faq = ({ pageTransition }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  // Select content JSON file based on language
  let content;
  language === 'en' ? (content = EnContent) : (content = FiContent);

  return (
    <motion.div
      variants={pageTransition}
      initial='out'
      animate='in'
      exit='out'
      className='w-full max-w-4xl mx-auto overflow-hidden bg-white lg:rounded-md'
    >
      <div className='px-6 py-8 sm:px-12 text-fmi-blue'>
        <Helmet>
          {language === 'en' ? (
            <title>FAQ | FMI-SAMPO</title>
          ) : (
            <title>FAQ | FMI-SAMPO</title>
          )}
        </Helmet>
	<h1 className='text-2xl font-semibold'>
          {language === 'en'
            ? 'Acknowledgements'
            : 'Kiitokset'}
        </h1>
        <div className='mt-6'>
            <p className='pl-4 mt-4'>
		The Scientific colour maps batlow [O3] and lajolla [SO2, Aerosol Index] (Crameri 2018) are used on this site to prevent visual distortion of the data and exclusion of readers with colour-vision deficiencies (Crameri et al., 2020) starting December 20, 2022.
	    </p>
	    <p className='pl-4 mt-4'>
		The software : Crameri, F. (2018a), Scientific colour maps. Zenodo. http://doi.org/10.5281/zenodo.1243862
	    </p>
	    <p className='pl-4 mt-4'>
The research : Crameri, F., G.E. Shephard, and P.J. Heron (2020), The misuse of colour in science communication, Nature Communications, 11, 5444. doi: 10.1038/s41467-020-19160-7
	    </p>

<div><br></br><br></br></div>
	    
        </div>
        <h1 className='text-2xl font-semibold'>
          {language === 'en'
            ? 'Frequently asked questions'
            : 'Usein kysytyt kysymykset'}
        </h1>
        <div className='mt-6'>
          {content.map((faq) => {
            return (
              <div key={faq.question} className='py-4'>
                <h2 className='font-semibold'>{faq.question}</h2>
                <p className='pl-4 mt-4'>{faq.answer}</p>
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default Faq;

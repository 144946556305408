import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

import EnContent from '../../content/en/announcement';
import FiContent from '../../content/fi/announcement';

const Announcement = () => {
  const context = useContext(LanguageContext);
  const { language } = context;

  // Select content JSON file based on language
  let content;
  language === 'en' ? (content = EnContent) : (content = FiContent);

  if (content.announcement.length > 0) {
    return (
      <div className='bg-fmi-turquoise text-fmi-turquoise py-6 px-6 lg:px-0 font-bold'>
        <div className='max-w-4xl mx-auto flex flex-col md:flex-row items-center'>
          <div className='mb-4 md:mr-4 md:mb-0'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-12 w-12'
              viewBox='0 0 48 48'
            >
              <g fill='none' fillRule='nonzero'>
                <rect width='48' height='48' fill='#02B8CE' rx='24' />
                <rect width='4' height='12' x='22' y='21' fill='#FFF' rx='2' />
                <circle cx='24' cy='16.5' r='2.5' fill='#FFF' />
              </g>
            </svg>
          </div>
          <p>{content.announcement}</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Announcement;

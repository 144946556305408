import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { format, getYear } from 'date-fns';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import SocialShare from '../layout/SocialShare';
import Placeholder from '../../assets/img/globe-blur.jpg';

const IndividualImages = ({ startDate, product, sourceForDateSearch }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  const [activeDate, setActiveDate] = useState(startDate);
  const [activeImage, setActiveImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [urls, setUrls] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  // Set product for GET request
  let queryProduct;

  if (product === 'uvindex') {
    queryProduct = 'uvi';
  } else if (product === 'dailydose') {
    queryProduct = 'dd';
  } else if (product === 'o3') {
    queryProduct = 'o3';
  } else {
    queryProduct = product;
  }

  // Set source for GET request
  let querySource;

  if (sourceForDateSearch === 'OMPS Alaska') {
    querySource = 'alaska';
  } else {
    querySource = sourceForDateSearch;
  }

  // Set date for GET request
  useEffect(() => {
    setActiveDate(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  let year = getYear(activeDate);
  let month = format(new Date(activeDate), 'MM');
  let day = format(new Date(activeDate), 'dd');

  const getImages = async () => {
    setError(false);
    setLoading(true);
    try {
      const res = await axios.get(
        `https://sampo.fmi.fi/api.php?type=individual&product=${queryProduct}&source=${querySource}&year=${year}&month=${month}&day=${day}`
      );
      setUrls(res.data);
      setLoading(false);
      setActiveImage(res.data[0]);
      setError(false);
    } catch (err) {
      setUrls([]);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, activeDate, sourceForDateSearch]);

  useEffect(() => {
    setImageLoading(true);
    setImageError(false);
    const img = new Image();
    img.onerror = () => {
      if (activeImage) {
        setImageLoading(false);
        setImageError(true);
      }
    };
    img.onload = () => {
      setImageLoading(false);
      setImageError(false);
      document.getElementById('main-image').src = activeImage;
    };
    img.src = activeImage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImage]);

  // Show loading animation on button
  const buttonIcon = (url) => {
    if (imageLoading && url === activeImage) {
      return <ClipLoader size={15} color={'#e7f0fa'} />;
    } else if (imageError && url === activeImage) {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          className='w-4 h-4'
        >
          <path
            fill='#e7f0fa'
            d='M19.511 17.98L10.604 1.348a.697.697 0 00-1.208 0L.49 17.98a.675.675 0 00.005.68c.125.211.352.34.598.34h17.814a.694.694 0 00.598-.34.677.677 0 00.006-.68zM11 17H9v-2h2v2zm0-3.5H9V7h2v6.5z'
          />
        </svg>
      );
    } else {
      return null;
    }
  };

  const image = () => {
    if (error) {
      return (
        <div className='relative flex flex-col items-center justify-between'>
          <div className='flex items-center justify-center py-16'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              className='w-6 h-6'
            >
              <path
                fill='#e7f0fa'
                d='M19.511 17.98L10.604 1.348a.697.697 0 00-1.208 0L.49 17.98a.675.675 0 00.005.68c.125.211.352.34.598.34h17.814a.694.694 0 00.598-.34.677.677 0 00.006-.68zM11 17H9v-2h2v2zm0-3.5H9V7h2v6.5z'
              />
            </svg>
            <span className='px-4 text-sm font-bold text-gray-600 uppercase'>
              {language === 'en' ? 'No images found' : 'Kuvia ei löytynyt'}
            </span>
          </div>
        </div>
      );
    } else if (loading) {
      return (
        <div className='relative flex flex-col items-center justify-between'>
          <img src={Placeholder} id='main-image' className='w-auto' alt='' />
        </div>
      );
    } else {
      return (
        <div className='relative flex flex-col items-center justify-between h-full'>
          <img
            src={Placeholder}
            id='main-image'
            className='object-contain w-auto h-full'
            alt={
              product +
              ' ' +
              sourceForDateSearch +
              ' data for ' +
              format(new Date(activeDate), 'M/d/yyyy')
            }
          />
          <SocialShare url={activeImage} />
        </div>
      );
    }
  };

  return (
    <div className='flex flex-col mx-auto mb-12 overflow-hidden bg-white border-b border-gray-300 md:flex-row lg:mb-0 md:max-h-156 lg:max-h-148 lg:rounded-md lg:max-w-4xl lg:mt-12'>
      <div className='flex flex-wrap md:w-1/4 md:flex-no-wrap md:flex-col md:overflow-y-scroll md:overflow-scroll'>
        {urls.map((url, index) => {
          return (
            <button
              key={url}
              onMouseEnter={() => setActiveImage(url)}
              className={
                'relative flex flex-grow items-center justify-between py-2 w-1/3 sm:w-1/4 md:w-auto border-b border-l border-r border-gray-300 min-h-10' +
                (url === activeImage
                  ? ' bg-fmi-darkblue text-white'
                  : ' bg-fmi-lightgray text-fmi-blue')
              }
            >
              <div className='w-5/6 text-xs sm:text-base'>{`${
                language === 'en' ? 'Image ' : 'Kuva '
              } ${index + 1}`}</div>
              <div className='w-1/6 mr-1'>{buttonIcon(url)}</div>
            </button>
          );
        })}
      </div>
      <div className='flex items-center justify-center w-full md:w-3/4'>
        {image()}
      </div>
    </div>
  );
};

export default IndividualImages;

import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import Modal from 'react-modal';
import ProductImages from './ProductImages';
import SocialShare from '../layout/SocialShare';
import ClipLoader from 'react-spinners/ClipLoader';

Modal.setAppElement('#root');

// Styling for modal
const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(48, 49, 147, 0.5)',
    zIndex: '70',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '750px',
    margin: '0 auto',
    top: 'auto',
    bottom: 'auto',
  },
};

const ImageTable = ({ sources, product }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const openModal = (e, selectedUrl) => {
    e.preventDefault();
    setSelectedImage(selectedUrl);
    if (window.innerWidth >= 640) {
      setIsOpen(true);
    }
  };

  // Modal image loader
  useEffect(() => {
    if (!selectedImage) return;

    if (window.innerWidth >= 640) {
      setLoading(true);
      setError(false);
      const img = new Image();
      img.onload = () => {
        setLoading(false);
        setError(false);
        document.getElementById('modal-image').src = selectedImage;
      };
      img.onerror = () => {
        setError(true);
        setLoading(false);
      };
      img.src = selectedImage;
      return () => (img.src = '');
    }
  }, [selectedImage]);

  const closeModal = () => {
    setIsOpen(false);
    setError(false);
    setSelectedImage('');
  };

  return (
    <div
      className={`'flex flex-col justify-center mx-auto lg:mt-12 bg-white items center overflow-hidden lg:rounded-md lg:max-w-4xl ${
        sources.length > 1 ? 'xl:max-w-7xl' : 'xl:max-w-4xl'
      }`}
    >
      <div className='flex flex-col justify-center overflow-y-scroll xl:flex-row xl:px-8 xl:pt-8 xl:pb-8'>
        {sources.map((source) => {
          return (
            <ProductImages
              key={source}
              source={source}
              product={product}
              openModal={openModal}
            />
          );
        })}
      </div>
      <div className='w-full border-t lg:h-16'></div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        shouldCloseOnOverlayClick={true}
      >
        <button className='font-semibold text-fmi-blue' onClick={closeModal}>
          {language === 'en' ? 'Close' : 'Sulje'}
        </button>
        {loading ? (
          <div className='flex items-center justify-center my-24'>
            <ClipLoader size={100} color={'#303193'} />
          </div>
        ) : null}
        {error ? (
          <div className='py-12 font-semibold text-gray-500'>
            {language === 'en' ? 'Image not found' : 'Kuvaa ei löytynyt'}
          </div>
        ) : null}
        <img
          id='modal-image'
          alt={product}
          className={!loading && !error ? 'block' : 'hidden'}
        />
        <SocialShare url={selectedImage} />
      </Modal>
    </div>
  );
};

export default ImageTable;

import React from 'react';

const SourceButton = ({
  sourceForDateSearch,
  setSourceForDateSearch,
  text
}) => {
  return (
    <button
      onClick={() => setSourceForDateSearch(text)}
      className={
        'px-4 py-2 m-1 rounded font-bold hover:bg-fmi-darkblue hover:text-white' +
        (sourceForDateSearch === text
          ? ' bg-fmi-darkblue text-white'
          : ' bg-fmi-lightgray text-fmi-blue')
      }
    >
      {text}
    </button>
  );
};

export default SourceButton;

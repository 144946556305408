import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

const ProductButton = ({ product, setProduct, props }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  // Select button language
  let buttonText;
  language === 'en'
    ? (buttonText = props.enButtonText)
    : (buttonText = props.fiButtonText);

  return (
    <button
      dangerouslySetInnerHTML={{ __html: buttonText }}
      onClick={() => setProduct(props.product)}
      className={
        'px-4 py-2 m-1 rounded font-bold hover:bg-fmi-darkblue hover:text-white' +
        (props.product === product
          ? ' bg-fmi-darkblue text-white'
          : ' bg-fmi-lightgray text-fmi-blue')
      }
    ></button>
  );
};

export default ProductButton;

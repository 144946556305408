import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

const LatestButton = ({ productContent, latestDataType, chooseLatest }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  // Select button language
  let buttonText;
  language === 'en'
    ? (buttonText = productContent.enButtonText)
    : (buttonText = productContent.fiButtonText);

  return (
    <button
      onClick={() => chooseLatest(productContent.product)}
      className='linkhover font-medium p-3 focus:outline-none'
    >
      <span
        dangerouslySetInnerHTML={{ __html: buttonText }}
        className={
          'link' +
          (latestDataType === productContent.product
            ? ' border-bottom-fmi'
            : '')
        }
      ></span>
    </button>
  );
};

export default LatestButton;

import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const LatestImage = ({ latestDataType, latestSource }) => {
  const [loading, setLoading] = useState(true);

  // Get folder for image URL
  const imageFolder = () => {
    if (latestSource === 'OMI Sodankylä') {
      return 'images';
    } else if (latestSource === 'OMPS Sodankylä') {
      return 'images_omps';
    } else if (latestSource === 'OMPS-NOAA-20') {
      return 'images_omps_noaa';
    } else if (latestSource === 'OMPS Alaska/Sod.') {
      return 'images_omps_pole';
    } else if (latestSource === 'VIIRS') {
      return 'images_viirs';
    } else {
      return 'images_omps_alaska';
    }
  };

  // Get name of image for URL
  const imageName = () => {
    if (latestSource === 'OMPS Alaska/Sod.') {
      return `${latestDataType}_composite_1.jpg`;
    } else if (latestSource === 'OMPS-NOAA-20') {
	return `${latestDataType}_composite_1.png`;
    } else if (latestSource === 'VIIRS') {
      return `${latestDataType}_composite_1.png`;
    } else {
      return `${latestDataType}_composite_1.jpg`;
    }
  };

  // Combine folder and name for image URL
  let imageUrl = `https://sampo.fmi.fi/kuvat/${imageFolder()}/${imageName()}`;

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.onload = () => {
      setLoading(false);
      document.getElementById('latest-image').src = imageUrl;
    };
    img.src = imageUrl;
    return () => (img.src = '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestDataType, latestSource]);

  if (loading) {
    return (
      <div className='flex items-center justify-center w-full h-full pt-8 lg:pt-0'>
        <ClipLoader size={100} color='#303193' />
      </div>
    );
  } else {
    return (
      <img
        id='latest-image'
        alt={`Latest ${latestDataType} data from ${latestSource}`}
        className='max-w-xs pt-8 sm:max-w-xl lg:pt-0'
      />
    );
  }
};

export default LatestImage;

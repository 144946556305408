import React from 'react';

const Overlay = ({ menuOpen, toggleMenu }) => {
  if (menuOpen) {
    return (
      <div
        onClick={toggleMenu}
        id='overlay'
        className='fixed top-0 bottom-0 left-0 right-0 z-40 min-w-full min-h-full cursor-pointer'
        style={{ backgroundColor: 'rgba(48, 49, 147, 0.5)' }}
      ></div>
    );
  }
  return null;
};

export default Overlay;

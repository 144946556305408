import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { LanguageProvider } from './LanguageContext';

import './App.css';
import Overlay from './components/layout/Overlay';
import Header from './components/layout/Header';
import Latest from './components/latest/Latest';
import Products from './components/products/Products';
import Faq from './components/layout/Faq';
import Highlights from './components/layout/Highlights';
import Info from './components/layout/Info';
import Footer from './components/layout/Footer';
import ScrollToTop from './components/layout/ScrollToTop';
import Announcement from './components/layout/Announcement';

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle mobile menu and prevent scrolling
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle('overflow-hidden');
  };

  // State and functions for homepage
  const [latestDataType, setLatestDataType] = useState('o3');
  const [latestSource, setLatestSource] = useState('OMPS Sodankylä');

  // State and functions for Products page
  const [product, setProduct] = useState('o3');
  const [type, setType] = useState('composite');
  const [startDate, setStartDate] = useState(null);
  const [sourceForDateSearch, setSourceForDateSearch] = useState('OMI');

  const pageTransition = {
    in: { opacity: 1 },
    out: { opacity: 0.5 },
  };

  return (
    <LanguageProvider>
      <div className='App'>
        <Router>
          <ScrollToTop />
          <Overlay menuOpen={menuOpen} toggleMenu={toggleMenu} />
          <Header menuOpen={menuOpen} toggleMenu={toggleMenu} />
          <main>
            <Announcement />
            <div className='flex justify-center lg:p-12 bg-fmi-gradient'>
              <div className='w-full overflow-hidden lg:rounded-md'>
                <AnimatePresence>
                  <Switch>
                    <Route exact path='/'>
                      <Latest
                        latestDataType={latestDataType}
                        setLatestDataType={setLatestDataType}
                        latestSource={latestSource}
                        setLatestSource={setLatestSource}
                        pageTransition={pageTransition}
                      />
                    </Route>
                    <Route exact path='/products'>
                      <Products
                        product={product}
                        setProduct={setProduct}
                        type={type}
                        setType={setType}
                        sourceForDateSearch={sourceForDateSearch}
                        setSourceForDateSearch={setSourceForDateSearch}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        pageTransition={pageTransition}
                      />
                    </Route>
                    <Route exact path='/faq'>
                      <Faq pageTransition={pageTransition} />
                    </Route>
                    <Route exact path='/highlights'>
                      <Highlights pageTransition={pageTransition} />
                    </Route>
                    <Route>
                      <Redirect to='/' />
                    </Route>
                  </Switch>
                </AnimatePresence>
              </div>
            </div>
            <Info />
          </main>
          <Footer />
        </Router>
      </div>
    </LanguageProvider>
  );
};

export default App;

import React from 'react';

const LatestSourceButton = ({ source, latestSource, setLatestSource }) => {
  return (
    <button
      onClick={() => setLatestSource(source)}
      className={
        'p-4 flex-grow font-medium hover:bg-fmi-darkblue hover:text-white' +
        (latestSource === source
          ? ' bg-fmi-darkblue text-white'
          : ' bg-fmi-lightgray text-fmi-blue')
      }
    >
      {source}
    </button>
  );
};

export default LatestSourceButton;

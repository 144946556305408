import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { add, format, getYear, subDays, differenceInDays } from 'date-fns';
import ClipLoader from 'react-spinners/ClipLoader';
import SocialShare from '../layout/SocialShare';
import Placeholder from '../../assets/img/globe-blur.jpg';

const CompositeImages = ({ startDate, product, sourceForDateSearch }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [activeDate, setActiveDate] = useState(null);

  const days = [];

  // Select first day on render
  useEffect(() => {
    setActiveDate(days[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  // Calculate a month (or less if start date is close to current date) from selected start date
  if (new Date(startDate) > subDays(new Date(), 30)) {
    let difference = Math.abs(
      differenceInDays(new Date(), new Date(startDate))
    );
    for (let i = 0; i < difference; i++) {
      let day = add(new Date(startDate), { days: i });
      days.push(day);
    }
  } else {
    for (let i = 0; i < 30; i++) {
      let day = add(new Date(startDate), { days: i });
      days.push(day);
    }
  }

  // Get image URL
  let selectedImage = '';

  let formattedDate = format(new Date(activeDate), "yyyy'm'MMdd");

  if (sourceForDateSearch === 'VIIRS') {
    formattedDate = format(new Date(activeDate), 'yyyy-MM-dd');
  }
  if (sourceForDateSearch === 'OMPS-NOAA-20') {
    formattedDate = format(new Date(activeDate), 'yyyy-MM-dd');
  }

  let activeYear = getYear(new Date(activeDate));

  if (product === 'o3' && sourceForDateSearch === 'OMI') {
    selectedImage = `https://sampo.fmi.fi/images_history/${activeYear}/composites/OMI-Aura_OMDOAO3_${formattedDate}.jpg`;
  } else if (product === 'o3' && sourceForDateSearch === 'OMPS') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps/${activeYear}/composites/OMPS-O3_${formattedDate}.jpg`;
  } else if (product === 'o3' && sourceForDateSearch === 'OMPS Alaska') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_alaska/${activeYear}/composites/OMPS-ALASKA-O3_${formattedDate}.jpg`;
  } else if (product === 'o3' && sourceForDateSearch === 'OMPS Alaska/Sod.') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_pole/${activeYear}/composites/OMPS-ALASKAs-O3_${formattedDate}.jpg`;
  } else if (product === 'o3' && sourceForDateSearch === 'OMPS-NOAA-20') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_noaa/${activeYear}/composites/OMPS_NOAA-20_O3_${formattedDate}_composite.png`
  } else if (product === 'so2' && sourceForDateSearch === 'OMI') {
    selectedImage = `https://sampo.fmi.fi/images_history/${activeYear}/composites/OMI-Aura_SO2_${formattedDate}.jpg`;
  } else if (product === 'so2' && sourceForDateSearch === 'OMPS') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps/${activeYear}/composites/OMPS-SO2_${formattedDate}.jpg`;
  } else if (product === 'so2' && sourceForDateSearch === 'OMPS Alaska') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_alaska/${activeYear}/composites/OMPS-ALASKA-SO2_${formattedDate}.jpg`;
  } else if (product === 'so2' && sourceForDateSearch === 'OMPS Alaska/Sod.') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_pole/${activeYear}/composites/OMPS-ALASKAs-SO2_${formattedDate}.jpg`;
  } else if (product === 'so2' && sourceForDateSearch === 'VIIRS') {
    selectedImage = `https://sampo.fmi.fi/images_history_viirs/${activeYear}/composites/VIIRS_NOAA-20_SO2_${formattedDate}_composite.png`;
  } else if (product === 'so2' && sourceForDateSearch === 'OMPS-NOAA-20') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_noaa/${activeYear}/composites/OMPS_NOAA-20_SO2_${formattedDate}_composite.png`;
  } else if (product === 'uvindex' && sourceForDateSearch === 'OMI') {
    selectedImage = `https://sampo.fmi.fi/images_history/${activeYear}/composites/OMI-Aura_UVI_${formattedDate}.jpg`;
  } else if (product === 'dailydose' && sourceForDateSearch === 'OMI') {
    selectedImage = `https://sampo.fmi.fi/images_history/${activeYear}/composites/OMI-Aura_DD_${formattedDate}.jpg`;
  } else if (product === 'uvai' && sourceForDateSearch === 'OMI') {
    selectedImage = `https://sampo.fmi.fi/images_history/${activeYear}/composites/OMI-Aura_UVAI_${formattedDate}.jpg`;
  } else if (product === 'uvai' && sourceForDateSearch === 'OMPS') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps/${activeYear}/composites/OMPS-UVAI_${formattedDate}.jpg`;
  } else if (product === 'uvai' && sourceForDateSearch === 'OMPS Alaska') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_alaska/${activeYear}/composites/OMPS-ALASKA-UVAI_${formattedDate}.jpg`;
  } else if (product === 'uvai' && sourceForDateSearch === 'OMPS Alaska/Sod.') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_pole/${activeYear}/composites/OMPS-ALASKAs-UVAI_${formattedDate}.jpg`;
  } else if (product === 'uvai' && sourceForDateSearch === 'VIIRS') {
    selectedImage = `https://sampo.fmi.fi/images_history_viirs/${activeYear}/composites/VIIRS_NOAA-20_UVAI_${formattedDate}_composite.png`;
  } else if (product === 'uvai' && sourceForDateSearch === 'OMPS-NOAA-20') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_noaa/${activeYear}/composites/OMPS_NOAA-20_UVAI_${formattedDate}_composite.png`;
  } else if (product === 'cloud' && sourceForDateSearch === 'OMI') {
    selectedImage = `https://sampo.fmi.fi/images_history/${activeYear}/composites/OMI-Aura_CLOUD_${formattedDate}.jpg`;
  } else if (product === 'cloud' && sourceForDateSearch === 'OMPS') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps/${activeYear}/composites/OMPS-CLOUD_${formattedDate}.jpg`;
  } else if (product === 'cloud' && sourceForDateSearch === 'OMPS Alaska') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_alaska/${activeYear}/composites/OMPS-ALASKA-CLOUD_${formattedDate}.jpg`;
  } else if (product === 'cloud' && sourceForDateSearch === 'OMPS Alaska/Sod.') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_pole/${activeYear}/composites/OMPS-ALASKAs-CLOUD_${formattedDate}.jpg`;
  } else if (product === 'cloud' && sourceForDateSearch === 'OMPS-NOAA-20') {
    selectedImage = `https://sampo.fmi.fi/images_history_omps_noaa/${activeYear}/composites/OMPS_NOAA-20_CLOUD_${formattedDate}_composite.png`;
  }

  // Image loader
  useEffect(() => {
    setLoading(true);
    setError(false);
    const img = new Image();
    img.onerror = () => {
      if (activeDate) {
        setLoading(false);
        setError(true);
      }
    };
    img.onload = () => {
      setLoading(false);
      setError(false);
      document.getElementById('main-image').src = selectedImage;
    };
    img.src = selectedImage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate, product, sourceForDateSearch]);

  // Show loading animation on button
  const buttonIcon = (day) => {
    if (loading && String(day) === String(activeDate)) {
      return <ClipLoader size={15} color={'#e7f0fa'} />;
    } else if (error && String(day) === String(activeDate)) {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          className='w-4 h-4'
        >
          <path
            fill='#e7f0fa'
            d='M19.511 17.98L10.604 1.348a.697.697 0 00-1.208 0L.49 17.98a.675.675 0 00.005.68c.125.211.352.34.598.34h17.814a.694.694 0 00.598-.34.677.677 0 00.006-.68zM11 17H9v-2h2v2zm0-3.5H9V7h2v6.5z'
          />
        </svg>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='flex flex-col mx-auto mb-12 overflow-hidden bg-white border-b border-gray-300 lg:mt-12 lg:rounded-md lg:max-w-4xl md:flex-row lg:mb-0 md:max-h-156 lg:max-h-148'>
      <div className='flex flex-wrap md:w-1/4 md:flex-no-wrap md:flex-col md:overflow-y-scroll md:overflow-scroll'>
        {days.map((day) => {
          return (
            <button
              key={day}
              onMouseEnter={() => setActiveDate(day)}
              className={
                'relative flex flex-grow items-center py-2 w-1/3 sm:w-1/4 md:w-auto border-b border-l border-r border-gray-300 min-h-10' +
                (String(day) === String(activeDate)
                  ? ' bg-fmi-darkblue text-white'
                  : ' bg-fmi-lightgray text-fmi-blue')
              }
            >
              <div className='w-5/6 text-xs sm:text-base'>
                {language === 'en'
                  ? day.toLocaleDateString('en-US')
                  : day.toLocaleDateString('fi-FI')}
              </div>
              <div className='w-1/6 mr-1'>{buttonIcon(day)}</div>
            </button>
          );
        })}
      </div>
      <div className='flex items-center justify-center w-full md:w-3/4'>
        <div className='relative flex flex-col items-center justify-between h-full'>
          <img
            src={Placeholder}
            id='main-image'
            className='object-contain w-auto h-full'
            alt={
              product + ' ' + sourceForDateSearch + ' data for ' + activeDate
            }
          />
          <SocialShare url={selectedImage} />
        </div>
      </div>
    </div>
  );
};

export default CompositeImages;

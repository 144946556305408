import React, { useEffect, useContext, Fragment } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import ProductButton from './ProductButton';
import SourceButton from './SourceButton';
import Content from '../../content/products2';
import ImageTable from './ImageTable';
import DatePicker from 'react-datepicker';
import CompositeBrowser from './CompositeBrowser';
import IndividualBrowser from './IndividualBrowser';

import 'react-datepicker/dist/react-datepicker.css';
import { enUS, fi } from 'date-fns/locale';
import { subDays } from 'date-fns';

const Products = ({
  product,
  setProduct,
  type,
  setType,
  sourceForDateSearch,
  setSourceForDateSearch,
  startDate,
  setStartDate,
  pageTransition,
}) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  const sources = [];

  for (let i = 0; i < Content.length; i++) {
    if (Content[i].product === product) {
      Content[i].sources.forEach((source) => sources.push(source));
    }
  }

  const sourcesForDateSearch = [];

  for (let i = 0; i < Content.length; i++) {
    if (Content[i].product === product) {
      Content[i].sourcesForDateSearch.forEach((source) =>
        sourcesForDateSearch.push(source)
      );
    }
  }

  useEffect(() => {
    if (!sourcesForDateSearch.includes(sourceForDateSearch)) {
      setSourceForDateSearch(sourcesForDateSearch[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcesForDateSearch, sourceForDateSearch]);

  return (
    <motion.div
      variants={pageTransition}
      initial='out'
      animate='in'
      exit='out'
      className='w-full overflow-hidden'
    >
      <div className='mx-auto bg-white border-b border-gray-300 lg:max-w-4xl sm:p-4 lg:p-8 lg:rounded-md'>
        <Helmet>
          {language === 'en' ? (
            <title>Products | FMI-SAMPO</title>
          ) : (
            <title>Tuotteet | FMI-SAMPO</title>
          )}
        </Helmet>
        <div className='p-4 mb-4'>
          <h2 className='mb-2 text-xl font-bold text-fmi-blue'>
            {language === 'en' ? 'Product' : 'Tuote'}
          </h2>
          <div className='flex flex-wrap flex-start'>
            {Content.map((productContent) => {
              return (
                <ProductButton
                  key={productContent.product}
                  props={productContent}
                  product={product}
                  setProduct={setProduct}
                />
              );
            })}
          </div>
        </div>

        <div className='p-4 mb-4'>
          <h2 className='mb-2 text-xl font-bold text-fmi-blue'>
            {language === 'en' ? 'Date' : 'Päivämäärä'}
</h2><h3>
              {language === 'en' ? 'Note: OMI O3 and AAI available only until February 2023' : 'Huom: OMI O3 and AAI saatavilla vain helmikuuhun 2023'}
          </h3>
          <div className='flex flex-col items-start sm:flex-row sm:items-center'>
            <button
              onClick={() => setStartDate(null)}
              className={
                'px-4 py-2 m-1 rounded font-bold hover:bg-fmi-darkblue hover:text-white' +
                (startDate === null
                  ? ' bg-fmi-darkblue text-white'
                  : ' bg-fmi-lightgray text-fmi-blue')
              }
            >
              {language === 'en' ? 'Show latest reads' : 'Viimeisin data'}
            </button>
            <span className='py-3 ml-5 text-lg font-semibold sm:ml-0 sm:px-3 md:px-6 text-fmi-blue'>
              {language === 'en' ? 'or' : 'tai'}
            </span>
            <DatePicker
              className={
                'appearance-none border-2 text-center border-gray-300 rounded py-2 px-2 leading-none font-semibold text-fmi-blue focus:outline-none focus:shadow-outline cursor-pointer' +
                (startDate !== null ? ' border-fmi-blue' : '')
              }
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={subDays(new Date(), 1)}
              placeholderText={
                language === 'en'
                  ? 'Click to select date'
                  : 'Valitse päivämäärä'
              }
              locale={language === 'en' ? enUS : fi}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              minDate={new Date(2011, 4, 11)}
              dateFormat={language === 'en' ? 'M/d/yyyy' : 'd.M.yyyy'}
            />
          </div>
        </div>
        {startDate !== null ? (
          <Fragment>
            <div className='p-4 mb-4'>
              <h2 className='mb-2 text-xl font-bold text-fmi-blue'>
                {language === 'en' ? 'Data type' : 'Kuvan tyyppi'}
              </h2>
              <div className='flex flex-wrap flex-start'>
                <button
                  onClick={() => setType('composite')}
                  className={
                    'px-4 py-2 m-1 rounded font-bold hover:bg-fmi-darkblue hover:text-white' +
                    (type === 'composite'
                      ? ' bg-fmi-darkblue text-white'
                      : ' bg-fmi-lightgray text-fmi-blue')
                  }
                >
                  {language === 'en' ? 'Composite' : 'Komposiitti'}
                </button>
                <button
                  onClick={() => setType('individual')}
                  className={
                    'px-4 py-2 m-1 rounded font-bold hover:bg-fmi-darkblue hover:text-white' +
                    (type === 'individual'
                      ? ' bg-fmi-darkblue text-white'
                      : ' bg-fmi-lightgray text-fmi-blue')
                  }
                >
                  {language === 'en'
                    ? 'Individual orbits'
                    : 'Yksittäiset ylilennot'}
                </button>
              </div>
            </div>
            <div className='p-4 mb-4'>
              <h2 className='mb-2 text-xl font-bold text-fmi-blue'>
                {language === 'en' ? 'Data source' : 'Lähde'}
              </h2>
              <div className='flex flex-wrap flex-start'>
                {sourcesForDateSearch.map((source) => {
                  return (
                    <SourceButton
                      key={source}
                      text={source}
                      sourceForDateSearch={sourceForDateSearch}
                      setSourceForDateSearch={setSourceForDateSearch}
                    />
                  );
                })}
              </div>
            </div>
          </Fragment>
        ) : null}
      </div>
      {startDate === null ? (
        <ImageTable sources={sources} product={product} />
      ) : type === 'composite' ? (
        <CompositeBrowser
          startDate={startDate}
          product={product}
          sourceForDateSearch={sourceForDateSearch}
        />
      ) : type === 'individual' ? (
        <IndividualBrowser
          startDate={startDate}
          product={product}
          sourceForDateSearch={sourceForDateSearch}
        />
      ) : null}
    </motion.div>)
    ;
};

export default Products;

import React from 'react';
import ProductImage from './ProductImage';
import subDays from 'date-fns/subDays';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import padStart from 'lodash/padStart';

// prettier-ignore
const generateUrl = (source, product, year, month, day) => {
  let base = 'https://sampo.fmi.fi';
  let formattedDate = `${year}m${month}${day}`;

  if (source === 'VIIRS') {
    formattedDate = `${year}-${month}-${day}`;
  }
  if (source === 'OMPS-NOAA-20') {
    formattedDate = `${year}-${month}-${day}`;
  }

  switch (source) {
    case 'OMPS Sodankylä':
      base = base + '/images_history_omps';
      break;
    case 'OMPS-NOAA-20':
      base = base + '/images_history_omps_noaa';
      break;
    case 'OMPS Alaska':
      base = base + '/images_history_omps_alaska';
      break;
    case 'OMPS Alaska/Sod.':
      base = base + '/images_history_omps_pole';
      break;
    case 'VIIRS':
      base = base + '/images_history_viirs';
      break
    case 'OMI Sodankylä':
    default:
      base = base + '/images_history';
  }

  base = `${base}/${year}/composites`

  if (product === 'o3'        && source === 'OMI Sodankylä')      return `${base}/OMI-Aura_OMDOAO3_${formattedDate}.jpg`;
  if (product === 'o3'        && source === 'OMPS Sodankylä')     return `${base}/OMPS-O3_${formattedDate}.jpg`;
  if (product === 'o3'        && source === 'OMPS Alaska')        return `${base}/OMPS-ALASKA-O3_${formattedDate}.jpg`;
  if (product === 'o3'        && source === 'OMPS Alaska/Sod.')   return `${base}/OMPS-ALASKAs-O3_${formattedDate}.jpg`;
  if (product === 'o3'        && source === 'OMPS-NOAA-20')       return `${base}/OMPS_NOAA-20_O3_${formattedDate}_composite.png`;
  if (product === 'so2'       && source === 'OMI Sodankylä')      return `${base}/OMI-Aura_SO2_${formattedDate}.jpg`;
  if (product === 'so2'       && source === 'OMPS Sodankylä')     return `${base}/OMPS-SO2_${formattedDate}.jpg`;
  if (product === 'so2'       && source === 'OMPS Alaska')        return `${base}/OMPS-ALASKA-SO2_${formattedDate}.jpg`;
  if (product === 'so2'       && source === 'OMPS Alaska/Sod.')   return `${base}/OMPS-ALASKAs-SO2_${formattedDate}.jpg`;
  if (product === 'so2'       && source === 'VIIRS')              return `${base}/VIIRS_NOAA-20_SO2_${formattedDate}_composite.png`;
  if (product === 'so2'       && source === 'OMPS-NOAA-20')       return `${base}/OMPS_NOAA-20_SO2_${formattedDate}_composite.png`;
  if (product === 'uvai'      && source === 'OMI Sodankylä')      return `${base}/OMI-Aura_UVAI_${formattedDate}.jpg`;
  if (product === 'uvai'      && source === 'OMPS Sodankylä')     return `${base}/OMPS-UVAI_${formattedDate}.jpg`;
  if (product === 'uvai'      && source === 'OMPS Alaska')        return `${base}/OMPS-ALASKA-UVAI_${formattedDate}.jpg`;
  if (product === 'uvai'      && source === 'OMPS Alaska/Sod.')   return `${base}/OMPS-ALASKAs-UVAI_${formattedDate}.jpg`;
  if (product === 'uvai'      && source === 'VIIRS')              return `${base}/VIIRS_NOAA-20_UVAI_${formattedDate}_composite.png`;
  if (product === 'uvai'      && source === 'OMPS-NOAA-20')       return `${base}/OMPS_NOAA-20_UVAI_${formattedDate}_composite.png`;
  if (product === 'cloud'     && source === 'OMI Sodankylä')      return `${base}/OMI-Aura_CLOUD_${formattedDate}.jpg`;
  if (product === 'cloud'     && source === 'OMPS Sodankylä')     return `${base}/OMPS-CLOUD_${formattedDate}.jpg`;
  if (product === 'cloud'     && source === 'OMPS Alaska')        return `${base}/OMPS-ALASKA-CLOUD_${formattedDate}.jpg`;
  if (product === 'cloud'     && source === 'OMPS Alaska/Sod.')   return `${base}/OMPS-ALASKAs-CLOUD_${formattedDate}.jpg`;
  if (product === 'cloud'     && source === 'OMPS-NOAA-20')       return `${base}/OMPS_NOAA-20_CLOUD_${formattedDate}_composite.png`;
  if (product === 'uvindex'   && source === 'OMI Sodankylä')      return `${base}/OMI-Aura_UVI_${formattedDate}.jpg`;
  if (product === 'dailydose' && source === 'OMI Sodankylä')      return `${base}/OMI-Aura_DD_${formattedDate}.jpg`;
};

const ProductImages = ({ source, product, openModal }) => {
  const images = [...Array(6).keys()].map((index) => {
    const date = subDays(new Date(), index);

    const year = getYear(date);
    const month = padStart(getMonth(date) + 1, 2, 0);
    const day = padStart(getDate(date), 2, 0);

    return generateUrl(source, product, year, month, day);
  });

  return (
    <div className='flex xl:flex-col xl:px-4 xl:py-0'>
      <span className='sticky left-0 flex items-center justify-center px-2 font-semibold text-center bg-white border-r border-gray-300 text-fmi-blue xl:px-0 xl:pb-4 xl:relative xl:block xl:w-auto xl:left-auto xl:border-none min-w-32 sm:min-w-48 xl:min-w-0'>
        {source}
      </span>
      <div className='flex flex-row font-semibold text-gray-500 xl:flex-col'>
        {images.map((image) => (
          <a
            key={image}
            href={image}
            style={{ width: '175px', height: '135px' }}
            onClick={(e) => openModal(e, image)}
            className='flex items-center justify-center mx-1 my-4 text-sm cursor-default sm:cursor-pointer xl:mx-0'
          >
            <ProductImage url={image} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProductImages;

import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import EnContent from '../../content/en/highlights';
import FiContent from '../../content/fi/highlights';

const Highlights = ({ pageTransition }) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  // Select content JSON file based on language
  let content;
  language === 'en' ? (content = EnContent) : (content = FiContent);

  return (
    <motion.div
      variants={pageTransition}
      initial='out'
      animate='in'
      exit='out'
      className='w-full max-w-4xl mx-auto overflow-hidden bg-white lg:rounded-md'
    >
      <div className='px-6 py-8 sm:px-12 text-fmi-blue'>
        <Helmet>
          {language === 'en' ? (
            <title>Highlights | FMI-SAMPO</title>
          ) : (
            <title>Kohokohtia | FMI-SAMPO</title>
          )}
        </Helmet>
        <h1 className='text-2xl font-semibold'>
          {language === 'en' ? 'Highlights' : 'Kohokohtia'}
        </h1>
        <div className='mt-6'>
          {content.map((highlight) => {
            return (
              <div key={highlight.title} className='py-8'>
                <h2 className='max-w-lg text-lg font-semibold'>
                  {highlight.title}
                </h2>
                <p className='max-w-lg pt-4 pb-8'>{highlight.description}</p>
                <img
                  src={highlight.img}
                  alt={highlight.title}
                  className='max-w-xs sm:max-w-lg'
                />
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default Highlights;

import React from 'react';

import LogoNasa from '../../assets/img/logo-nasa.svg';
import LogoNoaa from '../../assets/img/logo-noaa.svg';
import LogoKnmi from '../../assets/img/logo-knmi.svg';
import LogoGina from '../../assets/img/logo-gina.png';
import LogoDr from '../../assets/img/logo-dr.png';
import LogoNso from '../../assets/img/logo-nso.jpg';

const Partners = () => {
  return (
    <div className='flex mx-auto py-8 sm:px-12 sm:py-12 items-center justify-center flex-wrap'>
      <img
        src={LogoNasa}
        alt='National Aeronautics and Space Administration'
        className='w-1/6 object-contain p-6 min-w-32'
      />
      <img
        src={LogoNoaa}
        alt='National Oceanic and Atmospheric Administration'
        className='w-1/6 object-contain p-6 min-w-32'
      />
      <img
        src={LogoKnmi}
        alt='Royal Netherlands Meteorological Institute'
        className='w-1/6 object-contain p-6 min-w-32'
      />
      <img
        src={LogoNso}
        alt='Netherlands Space Office'
        className='w-1/6 object-contain p-6 min-w-32'
      />
      <img
        src={LogoDr}
        alt='Direct Readout'
        className='w-1/6 object-contain p-6 min-w-32'
      />
      <img
        src={LogoGina}
        alt='Geographic Information Network of Alaska'
        className='w-1/6 object-contain p-6 min-w-32'
      />
    </div>
  );
};

export default Partners;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Content from '../../content/products';
import LatestProductButton from './LatestProductButton';
import LatestSourceButton from './LatestSourceButton';
import LatestImage from './LatestImage';
import { LanguageContext } from '../../LanguageContext';
import { Helmet } from 'react-helmet';

const Latest = ({
  latestDataType,
  setLatestDataType,
  latestSource,
  setLatestSource,
  pageTransition,
}) => {
  const context = useContext(LanguageContext);
  const { language } = context;

  const sources = [];

  for (let i = 0; i < Content.length; i++) {
    if (Content[i].product === latestDataType) {
      Content[i].sources.forEach((source) => sources.push(source));
    }
  }

  const chooseLatest = (clickedDataType) => {
    setLatestDataType(clickedDataType);
    setLatestSource(sources[0]);
  };

  return (
    <motion.div
      variants={pageTransition}
      initial='out'
      animate='in'
      exit='out'
      className='flex flex-col w-full max-w-5xl mx-auto overflow-hidden bg-white lg:rounded-md lg:flex-row'
    >
      <div className='flex flex-col items-center justify-around px-8 py-6 lg:w-1/3 lg:items-start lg:px-12 lg:py-12 bg-fmi-lightgray text-fmi-blue'>
        <Helmet>
          <title>FMI-SAMPO</title>
        </Helmet>
        <h1 className='pt-4 pb-4 text-3xl font-bold lg:pb-0'>
          {language === 'en' ? 'Latest data' : 'Viimeisimmät kuvat'}
        </h1>
        <div className='flex flex-wrap items-start justify-center lg:py-12 lg:flex-col lg:justify-start'>
          {Content.map((productContent) => {
            return (
              <LatestProductButton
                key={productContent.product}
                productContent={productContent}
                latestDataType={latestDataType}
                setLatestDataType={setLatestDataType}
                chooseLatest={chooseLatest}
              />
            );
          })}
        </div>
        <Link
          to='/products'
          alt='Products'
          className='px-4 py-2 mt-6 font-bold text-white border rounded-full bg-fmi-darkblue lg:mt-0 lg:mb-6'
        >
          <div className='flex items-center justify-center'>
            {language === 'en' ? 'Search data' : 'Selaa kuvia'}
            <svg xmlns='http://www.w3.org/2000/svg' className='w-6 h-6'>
              <g fill='none'>
                <circle cx='12' cy='12' r='12' />
                <path
                  className='fill-current'
                  d='M9.793 18.207a1 1 0 01-.083-1.32l.083-.094 4.292-4.293-4.292-4.293a1 1 0 01-.083-1.32l.083-.094a1 1 0 011.32-.083l.094.083 5 5a1 1 0 01.083 1.32l-.083.094-5 5a1 1 0 01-1.414 0z'
                />
              </g>
            </svg>
          </div>
        </Link>
      </div>
      <div className='flex flex-col-reverse items-center justify-between lg:w-2/3 lg:flex-col lg:pt-12'>
        <LatestImage
          latestDataType={latestDataType}
          latestSource={latestSource}
        />
        <div className='flex flex-wrap w-full border-t border-gray-400 lg:flex-no-wrap lg:border-none lg:pt-4'>
          {sources.map((source) => {
            return (
              <LatestSourceButton
                key={source}
                source={source}
                latestSource={latestSource}
                setLatestSource={setLatestSource}
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default Latest;

import React, { useContext, useEffect, Fragment } from 'react';
import FmiLogo from '../../assets/img/fmi-logo.svg';
import IlLogo from '../../assets/img/il-logo.svg';
import FinnishFlag from '../../assets/img/finnish.svg';
import EnglishFlag from '../../assets/img/english.svg';
import { NavLink } from 'react-router-dom';
import { LanguageContext } from '../../LanguageContext';

const Header = ({ menuOpen, toggleMenu }) => {
  const context = useContext(LanguageContext);
  const { language, changeLanguage } = context;

  // Change HTML Lang attribute
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  return (
    <Fragment>
      <header className='hidden lg:block bg-white relative border-b border-gray-300'>
        <div className='mx-auto px-12 max-w-fmi flex justify-between'>
          <div className='w-1/4 py-6 pr-5 border-r border-gray-300 flex items-center'>
            <a
              href={
                language === 'en'
                  ? 'https://en.ilmatieteenlaitos.fi/'
                  : 'https://www.ilmatieteenlaitos.fi/'
              }
              target='_blank'
              rel='noopener noreferrer'
              alt={
                language === 'en'
                  ? 'Finnish Meteorological Institute'
                  : 'Ilmatieteen laitos'
              }
            >
              <img
                src={language === 'en' ? FmiLogo : IlLogo}
                alt={
                  language === 'en'
                    ? 'Finnish Meteorological Institute'
                    : 'Ilmatieteen laitos'
                }
                className='h-12'
              />
            </a>
          </div>
          <div className='w-1/2 py-6 px-5 border-r border-gray-300 flex justify-center items-center leading-tight'>
            <div className='text-center'>
              <h1 className='text-fmi-blue font-bold text-xl'>
                <NavLink exact to='/' alt='SAMPO Homepage'>
                  SAMPO
                </NavLink>
              </h1>
              <span className='pl-4 text-fmi-blue'>
                Satellite Measurements from Polar Orbit
              </span>
              <span className='block pt-1 text-gray-700 text-center uppercase text-xs font-semibold'>
                Instantly delivered Direct Readout products
              </span>
            </div>
          </div>
          <div className='w-1/4 py-6 pl-5 flex justify-end items-center'>
            <div>
              <button onClick={changeLanguage} className='flex items-center'>
                <span className='pr-4 text-fmi-blue'>
                  {language === 'en' ? 'Suomeksi' : 'In English'}
                </span>
                <img
                  className='h-8 w-8 rounded-full border border-gray-300 object-cover'
                  src={language === 'en' ? FinnishFlag : EnglishFlag}
                  alt=''
                />
              </button>
            </div>
          </div>
        </div>
      </header>

      <nav className='hidden lg:block sticky top-0 z-40 text-sm shadow-fmi-small bg-white leading-tight'>
        <div className='flex justify-center px-12 py-5'>
          <div className='flex text-fmi-blue items-center'>
            <div className='mx-4'>
              <div className='linkhover'>
                <NavLink
                  exact
                  to='/'
                  className='pb-1 link'
                  alt='Home'
                  activeClassName='border-bottom-fmi'
                >
                  {language === 'en' ? 'Home' : 'Etusivu'}
                </NavLink>
              </div>
            </div>
            <div className='mx-4'>
              <div className='linkhover'>
                {' '}
                <NavLink
                  exact
                  to='/products'
                  alt='Products'
                  className='py-1 link'
                  activeClassName='border-bottom-fmi'
                >
                  {language === 'en' ? 'Products' : 'Tuotteet'}
                </NavLink>
              </div>
            </div>
            <div className='mx-4'>
              <div className='linkhover'>
                <NavLink
                  exact
                  to='/faq'
                  alt='Frequently asked questions'
                  className='py-1 link'
                  activeClassName='border-bottom-fmi'
                >
                  FAQ
                </NavLink>
              </div>
            </div>
            <div className='mx-4'>
              <div className='linkhover'>
                <NavLink
                  exact
                  to='/highlights'
                  alt='Highlights'
                  className='py-1 link'
                  activeClassName='border-bottom-fmi'
                >
                  {language === 'en' ? 'Highlights' : 'Kohokohtia'}
                </NavLink>
              </div>
            </div>
            <div className='mx-1'>
              <div className='linkhover'>
              <a href="https://sampo.fmi.fi/tropomi_l3/index.php" target="_blank" rel="noopener noreferrer"
                onClick={toggleMenu}
                alt='S5p UV products'
                className='text-bold border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                activeClassName='border-fmi-darkblue bg-fmi-lightblue'
              >
                {language === 'en' ? 'S5p (Tropomi) L3 data and images' : 'Tropomi L3 data ja kuvat'}
              </a>
              </div>
            </div>
            <div className='mx-1'>
              <div className='linkhover'>
              <a href="https://sampo.fmi.fi/s5puv/index.html" target="_blank" rel="noopener noreferrer"
                onClick={toggleMenu}
                alt='S5p UV products'
                className='text-bold border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                activeClassName='border-fmi-darkblue bg-fmi-lightblue'
              >
                {language === 'en' ? 'S5p (Tropomi) UV products (external)' : 'Tropomi UV (ulkoinen)'}
              </a>
              </div>
            </div>
            <div className='mx-1'>
              <div className='linkhover'>
              <a href="https://sampo.fmi.fi/airpollution/no2/index.html" target="_blank" rel="noopener noreferrer"
                onClick={toggleMenu}
                alt='S5p NO2 over Finland'
                className='text-bold border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                activeClassName='border-fmi-darkblue bg-fmi-lightblue'
              >
                {language === 'en' ? 'S5p NO2 over Finland (external)' : 'Tropomi NO2 Suomessa (ulkoinen)'}
              </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <header className='sticky top-0 bg-white z-40 block lg:hidden shadow-fmi-small'>
        <nav>
          <div className='flex justify-between items-center px-5 py-3'>
            <div className='pr-5 flex justify-center items-center leading-tight'>
              <div className=''>
                <h1 className='text-fmi-blue font-bold text-xl'>
                  <NavLink exact to='/' alt='SAMPO Homepage'>
                    SAMPO
                  </NavLink>
                </h1>
                <span className='text-sm sm:text-base text-fmi-blue'>
                  Satellite Measurements <br className='block sm:hidden' />
                  from Polar Orbit
                </span>
                <span className='hidden sm:block pt-1 text-gray-700 uppercase text-xs font-semibold'>
                  Instantly delivered Direct Readout products
                </span>
              </div>
            </div>
            <div
              onClick={toggleMenu}
              id='mobile-menu-icon'
              className='flex justify-center items-center cursor-pointer'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48'>
                <g fill='none'>
                  <path d='M0 0h48v48H0z' />
                  <g fill='#303193' transform='translate(11 15)'>
                    <rect width='26' height='2' rx='1' />
                    <rect width='26' height='2' y='8' rx='1' />
                    <rect width='26' height='2' y='16' rx='1' />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          {menuOpen && (
            <div id='mobile-menu' className='border-t'>
              <div className='flex flex-col text-fmi-blue text-sm leading-relaxed'>
                <div className='flex w-full border-b border-300'>
                  <NavLink
                    exact
                    to='/'
                    onClick={toggleMenu}
                    alt='Home'
                    className='border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                    activeClassName='border-fmi-darkblue bg-fmi-lightblue'
                  >
                    {language === 'en' ? 'Home' : 'Etusivu'}
                  </NavLink>
                </div>
                <div className='flex w-full border-b border-300'>
                  <NavLink
                    exact
                    to='/products'
                    onClick={toggleMenu}
                    alt='Products'
                    className='border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                    activeClassName='border-fmi-darkblue bg-fmi-lightblue'
                  >
                    {language === 'en' ? 'Products' : 'Tuotteet'}
                  </NavLink>
                </div>
                <div className='flex w-full border-b border-300'>
                  <NavLink
                    exact
                    to='/faq'
                    onClick={toggleMenu}
                    alt='Frequently asked questions'
                    className='border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                    activeClassName='border-fmi-darkblue bg-fmi-lightblue'
                  >
                    FAQ
                  </NavLink>
                </div>
                <div className='flex w-full border-b border-300'>
                  <NavLink
                    exact
                    to='/highlights'
                    onClick={toggleMenu}
                    alt='Highlights'
                    className='border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                    activeClassName='border-fmi-darkblue bg-fmi-lightblue'
                  >
                    {language === 'en' ? 'Highlights' : 'Kohokohtia'}
                  </NavLink>
                </div>
                <div className='flex w-full border-b border-300'>
                  <a href="https://sampo.fmi.fi/s5puv/index.html" target="_blank" rel="noopener noreferrer"
                    onClick={toggleMenu}
                    alt='S5p UV products'
                    className='border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                    activeClassName='border-fmi-darkblue bg-fmi-lightblue'
                  >
                    {language === 'en' ? 'S5p UV products (external)' : 'Tropomi UV (ulkoinen)'}
                  </a>
                </div>
                <div className='flex w-full border-b border-300'>
                  <a href="https://sampo.fmi.fi/airquality/no2/index.html" target="_blank" rel="noopener noreferrer"
                    onClick={toggleMenu}
                    alt='S5p NO2 over Finland'
                    className='border-l-6 hover:border-fmi-darkblue w-full px-5 py-3 hover:bg-fmi-lightblue border-white'
                    activeClassName='border-fmi-darkblue bg-fmi-lightblue'
                  >
                    {language === 'en' ? 'S5p NO2 over Finland (external)' : 'Tropomi NO2 Suomessa (ulkoinen)'}
                  </a>
                </div>
              </div>

              <div className='pt-8 px-5 pb-5 flex flex-col-reverse sm:flex-row justify-between items-center'>
                <div className='sm:w-1/2 flex'>
                  <a
                    href={
                      language === 'en'
                        ? 'https://en.ilmatieteenlaitos.fi/'
                        : 'https://www.ilmatieteenlaitos.fi/'
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    alt={
                      language === 'en'
                        ? 'Finnish Meteorological Institute'
                        : 'Ilmatieteen laitos'
                    }
                  >
                    <img
                      src={language === 'en' ? FmiLogo : IlLogo}
                      alt='Finnish Meteorological Institute'
                      className='h-12'
                    />
                  </a>
                </div>
                <div className='sm:w-1/2 pb-8 sm:pb-0 flex sm:justify-end items-center'>
                  <button
                    onClick={changeLanguage}
                    className='flex flex-row-reverse sm:flex-row items-center'
                  >
                    <span className='px-4 text-fmi-blue'>
                      {language === 'en' ? 'Suomeksi' : 'In English'}
                    </span>
                    <img
                      className='h-8 w-8 rounded-full border border-gray-300 object-cover'
                      src={language === 'en' ? FinnishFlag : EnglishFlag}
                      alt=''
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
        </nav>
      </header>
    </Fragment>
  );
};

export default Header;

import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';

import FooterPattern from '../../assets/img/il-footer-pattern.png';
import FmiLogo from '../../assets/img/fmi-logo-white.svg';
import IlLogo from '../../assets/img/il-logo-white.svg';

const Footer = () => {
  const context = useContext(LanguageContext);
  const { language } = context;

  return (
    <footer className='bg-fmi-darkblue text-white'>
      <div className='shadow-fmi-footer'>
        <div className='flex flex-col md:flex-row max-w-5xl mx-auto justify-between items-center p-5 lg:p-12'>
          <div className='max-w-xs lg:max-w-sm'>
            <img
              src={language === 'en' ? FmiLogo : IlLogo}
              alt={
                language === 'en'
                  ? 'Finnish Meteorological Institute'
                  : 'Ilmatieteen laitos'
              }
              className='h-12'
            />
          </div>
          <div className='max-w-xs py-8 md:py-0'>
            <h3 className='font-bold mb-4'>
              {language === 'en' ? 'Contact' : 'Ota yhteyttä'}
            </h3>
            <span className='block mb-2'>
              {language === 'en'
                ? 'For questions about the SAMPO products, please contact:'
                : 'Jos haluat lisätietoja tuotteista, niin ota yhteyttä: '}
            </span>
            <a href='mailto:seppo.hassinen@fmi.fi' className='hover:underline'>
              seppo.hassinen@fmi.fi
            </a>
          </div>
        </div>
      </div>
      <div className='flex justify-center sm:justify-start'>
        <div className='hidden sm:block w-2/5'>
          <img src={FooterPattern} alt='' />
        </div>
        <div className='sm:w-3/5 flex justify-end max-w-6xl'>
          <div className='flex items-center justify-end px-6 py-6 sm:px-12 sm:py-0 text-sm sm:text-base'>
            &copy;{' '}
            {language === 'en'
              ? 'Finnish Meteorological Institute '
              : 'Ilmatieteen laitos '}
            {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
